import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import menu from './menu-icon.png';
import Slide from '@material-ui/core/slide';

class SideNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expand: false
        }
        this.setExpand = this.setExpand.bind(this);
    }

    setExpand(index) {
        this.setState(prevState => ({ expand: !prevState.expand }));
    }

    render() {
        return (
            <div class="side-navbar">
                <div className="side-items">
                    <div className="side-container"><Link to='/' className="side-item">HOME</Link></div>
                    <div>
                        <div className="side-container">
                            <div className="flex-center">
                                <Link className='subnav-container side-item' to='/therapists'>OUR THERAPISTS</Link>
                                <span class="material-symbols-outlined expander" onClick={this.setExpand}>
                                    {this.state.expand ? "expand_less" : "expand_more"}
                                </span>
                            </div>
                        </div>
                        <Slide direction="right" in={this.state.expand} mountOnEnter unmountOnExit>
                            <div className='subnav' >
                                <div className="side-container"><Link to='/therapists/tracy' className="side-item">TRACY BERTRIM</Link></div>
                                <div className="side-container"><Link to='/therapists/solmaz' className="side-item">SOLMAZ BARGHGIR</Link></div>
                                <div className="side-container"><Link to='/therapists/mycha' className="side-item">MYCHA HAUGHTON-LEWIS</Link></div>
                            </div>
                        </Slide>
                    </div>
                    <div className="side-container"><Link to='/services' className="side-item">SERVICES</Link></div>
                    <div className="side-container"><Link to='/faq' className="side-item">FAQ</Link></div>
                    <div className="side-container"><Link to='/contact' className="side-item">CONTACT</Link></div>
                </div>
            </div >
        )
    }
}

export default SideNavbar;
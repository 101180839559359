import React from 'react';
import './App.css';
import YuliaPic from './YuliaPic.jpg';
import pic2 from './About2.jpg';
import Topbar from './Topbar';
import Footer from './Footer';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

class Yulia extends React.Component {
    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Topbar />
                <div className="header-image5">
                    <div className="services1">Yulia Saveliev</div>
                </div>
                <div className='profile-container'>
                    <div className='profile-section'>
                        <img src={YuliaPic} alt="Stacey Gudmundsson" className='profile-pic' />
                        <div className='profile-overview-section'>
                            <div className='profile-header'>Overview</div>
                            <div className='profile-overview'>Populations Served</div>
                            <ul className='overview-list'>
                                <li>Adults</li>
                            </ul>
                            <div className='profile-overview'>Therapy Formats</div>
                            <ul className='overview-list'>
                                <li>Individual</li>
                            </ul>
                            <div className='profile-overview'>Main Areas of Focus</div>
                            <ul className='overview-list'>
                                <li>ADHD</li>
                                <li>Depression</li>
                                <li>Anxiety</li>
                                <li>Emotion Regulation</li>
                                <li>Relationship Issues</li>
                            </ul>
                        </div>
                    </div>
                    <br />
                    <div class="profile-bio">
                        <div>
                            <p className='profile-paragraph'>
                                Yulia (she/her) is a registered psychotherapist with the College of Registered Psychotherapists of Ontario. Yulia is a compassionate psychotherapist with over 10 years of experience in the mental health field, dedicated to helping individuals from diverse backgrounds find balance in their lives. Yulia values diversity and inclusion, recognizing that each person's unique experiences and identity play a significant role in their journey. Understanding that life is often filled with challenges, Yulia believes that embracing struggle is an essential part of personal growth. She specializes in supporting clients with ADHD, depression, anxiety, emotion regulation, and relationship issues.</p>
                            <p className='profile-paragraph'>Using an eclectic approach, Yulia draws from modalities including Dialectical Behavior Therapy (DBT), Cognitive Behavioral Therapy (CBT), Narrative Therapy, and Motivational Interviewing, tailoring her approach to each client's unique needs. By fostering resilience, inclusivity, and a sense of purpose, Yulia works collaboratively with clients to overcome obstacles and move toward their goals, navigating life’s complexities and creating lasting, meaningful change.</p>
                            <p className='profile-paragraph'>**Yulia provides  <i>virtual</i> therapy services</p>
                            <div class="services-button"><Link to='/contact' className="contact-submit contact-me">Interested in services? Click here</Link></div>
                        </div>
                        <img src={pic2} alt="image" className="image2 desktop" />
                    </div><br></br><br></br>
                </div>
                <Footer />
            </motion.div>
        )
    }
}

export default Yulia;
import React from 'react';
import './App.css';
import pic1 from './About1.jpg';
import pic2 from './About2.jpg';
import Topbar from './Topbar';
import Footer from './Footer';
import { AnimatePresence, motion } from 'framer-motion';
import { about1, about2, about3, about4, about5, about6, about7, about8 } from './TextContent.json';
import { Link } from 'react-router-dom';

class About extends React.Component {
    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Topbar />
                <div className="header-image5">
                    <div className="services1">Our Therapists</div>
                </div>
                <div className="about-top-container">
                    <h1>
                        Welcome to Tall Tree Psychotherapy!
                    </h1>
                    <div className='about-paragraph'>
                        We are committed to providing a safe and inclusive space for all people, regardless of race, culture, gender, sexual identity, social status, religious affiliation, age, or disability.
                    </div>
                    <div class="instructions">Please click on a therapist's name to learn more about them</div>
                    <div className='therapist-links'>
                        <Link to='/therapists/tracy' className="therapist-link">Tracy Bertrim</Link>
                        <Link to='/therapists/solmaz' className="therapist-link">Solmaz Barghgir</Link>
                    </div>
                    <div className='therapist-links'>
                        <Link to='/therapists/mycha' className="therapist-link">Mycha Haughton-Lewis</Link>
                        <Link to='/therapists/yulia' className="therapist-link">Yulia Saveliev</Link>
                    </div>
                </div>
                <Footer />
            </motion.div>
        )
    }
}

export default About;
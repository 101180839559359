import React from 'react';
import './App.css';
import Home2 from './Home2';
import Contact from './Contact';
import About from './About';
import Learn from './Learn';
import Services from './Services';
import FAQ from './FAQ';
import Tracy from './Tracy';
import Stacey from './Stacey';
import Maya from './Maya';
import Solmaz from './Solmaz';
import Mycha from './Mycha';
import Yulia from './Yulia';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

class RouterPage extends React.Component {

    render() {
        return (
            <Router basename={process.env.PUBLIC_URL}>
                <div className="App">
                    <AnimatePresence>
                        <Switch>
                            <Route path="/" exact component={Home2} />
                            <Route path="/contact" exact component={Contact} />
                            <Route path="/therapists" exact component={About} />
                            <Route path="/learn" exact component={Learn} />
                            <Route path="/services" exact component={Services} />
                            <Route path="/faq" exact component={FAQ} />
                            <Route path="/therapists/tracy" exact component={Tracy} />
                            <Route path="/therapists/stacey" exact component={Stacey} />
                            <Route path="/therapists/maya" exact component={Maya} />
                            <Route path="/therapists/solmaz" exact component={Solmaz} />
                            <Route path="/therapists/mycha" exact component={Mycha} />
                            <Route path="/therapists/yulia" exact component={Yulia} />
                        </Switch>
                    </AnimatePresence>
                </div>
            </Router>
        )
    }
}

export default RouterPage;